import { APIConnected } from '@/api/APIConnected'

const apiConnectedService = new APIConnected()

export default {
  methods: {
    createAuditSectionInformation (data, sectionInformation) {
      this.$store.commit('loadFromAPI')

      apiConnectedService.createAuditSectionInformation(
        this.token, sectionInformation, data)
        .then((result) => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast('Votre information a bien été ajouté', {
            id: 'notification-toast',
            title: 'Information ajoutée',
            variant: 'success',
            solid: true
          })

          this.informationAdded(result.data, sectionInformation)
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit createAuditSectionInformation API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },


    deleteAuditSectionInformation (deleteAuditSectionInformationId, sectionInformation) {
      this.$store.commit('loadFromAPI')
      apiConnectedService.deleteAuditSectionInformation(
        this.token, sectionInformation, deleteAuditSectionInformationId)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast('Votre information a bien été supprimée', {
            id: 'notification-toast',
            title: 'Suppression réussie',
            variant: 'danger',
            solid: true
          })

          this.informationDeleted(deleteAuditSectionInformationId, sectionInformation)
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit deleteAuditItem API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },


    modifyAuditSectionInformation (data, modifyAuditSectionInformationId, sectionInformation) {
      this.$store.commit('loadFromAPI')
      apiConnectedService.deleteAuditSectionInformation(
        this.token, sectionInformation, modifyAuditSectionInformationId, data)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$bvToast.hide('notification-toast')
            this.$bvToast.toast('Votre information a bien été modifiée', {
            id: 'notification-toast',
            title: 'Modification réussie',
            variant: 'danger',
            solid: true
          })
        })
        .catch((error) => {
          this.$store.commit('openGlobalErrorModal')
          throw new Error('Audit deleteAuditItem API Error : ' + String(error))
        })
        .finally(() => {
          this.$store.commit('endLoadingFromAPI')
        })
    },


  }
}
