<template>
  <div class="ElementsFinanciers" >
    <validationCartouche
      :hasCedantValidated="elementsFinanciersActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="elementsFinanciersActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Éléments financiers de l’activité cédée </h2>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="elementsFinanciersActiviteCedee.has_repreneur_validated && elementsFinanciersActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>


      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="elementsFinanciersActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="elementsFinanciersActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyElementsFinanciers"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="elementsFinanciersActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="elementsFinanciersActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{elementsFinanciersActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>, il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.comptes-bancaies-pro-collapse>
        Votre ou vos comptes bancaires courants professionnels
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="comptes-bancaies-pro-collapse" role="tabpanel">
        <p class='gavc-p'>
          Veuillez renseigner les informations concernant votre ou vos comptes bancaires courants (nom de la banque et adresse), puis charger le rib du compte (par mesure de sécurité).
        </p>

        <div class="w-50 mt-2 p-2 gavc-bg-bizarre" style="border-radius: 10px;" v-for="compteBancaire in elementsFinanciersActiviteCedee.compte_bancaires" :key="compteBancaire.id">
          Nom de la banque : {{compteBancaire.name}} / Adresse : {{compteBancaire.address}}
          <button
            v-if="cedantCanModified"
            class="ml-4 float-right"
            style="background-color: transparent !important; border: 0 !important;"
            @click="deleteAuditSectionInformation(compteBancaire.id, 'compteBancaire')">
            <i class="gavc-icon gavc-icon-cross"></i>
          </button>
        </div>
        <form v-if="cedantCanModified" class="form-inline mt-4" v-on:submit.prevent="addCompteBancaire()">
          <input
            class="gavc-input"
            type="text"
            id="add-compteBancaire-first-name"
            placeholder="Nom de la banque"
            maxlength="14"
            name="add-compteBancaire-first-name"
            v-model="newCompteBancaireName"
          >
          <input
            class="gavc-input"
            type="text"
            id="add-compteBancaire-address"
            placeholder="Adresse"
            name="add-compteBancaire-address"
            v-model="newCompteBancaireAddress"
          >

          <button
            class="gavc-btn"
            :disabled="$v.newCompteBancaireName.$invalid || $v.newCompteBancaireAddress.$invalid"
            type="submit"
          >
            Ajouter un compte bancaire
          </button>
        </form>


        <p class="mt-4">
          Documents à charger : <strong>Rib des comptes bancaires ci-dessus</strong>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'RIB', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('RIB', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'RIB', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="RIB"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.extraits-bancaires-collapse>
        Extraits bancaires mensuels
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="extraits-bancaires-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Les extraits bancaires mensuels des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter vos comptes, rendez-vous sur le site de votre banque ou contactez une agence"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <h4 class="gavc-h4 mt-2"> Extraits bancaires mensuels N-3</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_BANCAIRES_N3', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_BANCAIRES_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_BANCAIRES_N3', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="EXTRAITS_BANCAIRES_N3"
        />

        <h4 class="gavc-h4 mt-2"> Extraits bancaires mensuels N-2</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_BANCAIRES_N2', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_BANCAIRES_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_BANCAIRES_N2', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="EXTRAITS_BANCAIRES_N2"
        />

        <h4 class="gavc-h4 mt-2"> Extraits bancaires mensuels N-1</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'EXTRAITS_BANCAIRES_N1', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('EXTRAITS_BANCAIRES_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'EXTRAITS_BANCAIRES_N1', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="EXTRAITS_BANCAIRES_N1"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.pret-credit-collapse>
        Prêts et crédits
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>

      <b-collapse id="pret-credit-collapse" role="tabpanel">
        <h4 class="gavc-h4 mt-4"> Prêts </h4>
        <p>
          Documents à charger : <strong>Prêts avec mention et montant</strong>.
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PRET', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('PRET', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PRET', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="PRET"
        />

        <h4 class="gavc-h4 mt-2"> Ligne de crédits </h4>
        <p>
          Documents à charger : <strong>Ligne de crédit avec le montant à date par ligne de crédit</strong>.
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'CREDIT', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('CREDIT', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'CREDIT', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="CREDIT"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.cautionnements-collapse>
        Cautionnements
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="cautionnements-collapse" role="tabpanel">

        <p class="gavc-p">
          Documents à charger : <strong>Cautionnements engageant la société cédée</strong>.
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFinanciersActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFinanciersActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'CAUTIONNEMENT', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFinanciersActiviteCedee', false)"
          :items="filterDocuments('CAUTIONNEMENT', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'CAUTIONNEMENT', elementsFinanciersActiviteCedee.id, 'elementsFinanciersActiviteCedee')"
          type="CAUTIONNEMENT"
        />
      </b-collapse>


      <!-- ######## CEDANT BUTTONS ########## -->
      <div v-if="!elementsFinanciersActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @modify="modifyElementsFinanciers"
            @validate="validateElementsFinanciers(true)"
          />
        </div>
      </div>
      <div v-else-if="elementsFinanciersActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateElementsFinanciers(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>
    </div>


    <div v-if="!elementsFinanciersActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <validationSectionButton
          @validate="validateElementsFinanciers(true)"
          :withModifyButton="false"
        />
      </div>
    </div>
    <div v-else-if="elementsFinanciersActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="validateElementsFinanciers(false)"
        >
            Invalider cette étape
        </button>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsFinanciersActiviteCedee.has_cedant_validated"
          text="Le cédant a validé toute la section"
        />
      </div>
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsFinanciersActiviteCedee.has_repreneur_validated"
          text="Le repreneur a validé toute la section"
        />
      </div>
    </div>

    <div class="mt-4 text-center" v-if="elementsFinanciersActiviteCedee.has_repreneur_validated && elementsFinanciersActiviteCedee.has_cedant_validated">
      <button
        class="gavc-btn"
        @click="$emit('next-step')">
            Étape validée - Accéder à l'étape suivante
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { minLength, maxLength, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import auditSectionInformationMixins from '@/mixins/auditSectionInformationMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')

export default {
  name: 'ElementsFinanciers',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    auditSectionInformationMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingElementsFinanciers: false,
      elementsFinanciersActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        compte_bancaires: [],

      },

      newCompteBancaireName: '',
      newCompteBancaireAddress: '',

    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.elementsFinanciersActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getElementsFinanciers()
  },

  validations: {
    newCompteBancaireName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
    newCompteBancaireAddress: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },
  },

  methods: {
    filterDocuments (type, uploader) {
      return this.elementsFinanciersActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    addCompteBancaire () {
      const data = {
        'section': this.elementsFinanciersActiviteCedee.id,
        'name': this.newCompteBancaireName,
        'address': this.newCompteBancaireAddress,
      }
      this.createAuditSectionInformation(data, 'compteBancaire')
    },
    informationAdded(data, sectionInformation) {
      if(sectionInformation === 'compteBancaire') {
        this.elementsFinanciersActiviteCedee.compte_bancaires.push(data)
        this.newCompteBancaireName = ''
        this.newCompteBancaireAddress = ''
      }
    },
    informationDeleted(informationId, sectionInformation) {
      if(sectionInformation === 'compteBancaire') {
        this.elementsFinanciersActiviteCedee.compte_bancaires = this.elementsFinanciersActiviteCedee.compte_bancaires.filter(item => item.id !== informationId);
      }
    },
    documentModified () {
      this.getElementsFinanciers()
    },
    getElementsFinanciers () {
      this.isGettingElementsFinanciers = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'elementsFinanciersActiviteCedee', this.id)
      .then((result) => {
        this.elementsFinanciersActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFinanciers getElementsFinanciers API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingElementsFinanciers = false
      })
    },

    modifyElementsFinanciers () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'elementsFinanciersActiviteCedee', this.id, this.elementsFinanciersActiviteCedee)
      .then((result) => {
        this.elementsFinanciersActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFinanciers modifyElementsFinanciers API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateElementsFinanciers (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "elementsFinanciersActiviteCedee",  this.id, data)
      .then((result) => {
        this.elementsFinanciersActiviteCedee = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
        else {
            this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFinanciers validateElementsFinanciers API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
